export default function doOnce(fn: Function) {
  let done = false;
  let isRunning = false;
  return async function (...args: any[]) {
    if (done || isRunning) return;
    isRunning = true;
    try {
      await fn(...args);
      done = true;
    } finally {
      isRunning = false;
    }
  };
}
