/* eslint-disable no-control-regex */

const sanitizeInput = (input: string) => {
  if (!input) return '';

  return input
    .trim() // Remove leading and trailing whitespace
    .replace(/[\x00-\x1F]/g, '') // Remove control characters
    .replace(/[^a-zA-Z0-9\s',._:;-]/g, '') // Allow letters, numbers, spaces, and common punctuation
    .replace(/\s+/g, ' ') // Replace multiple spaces with a single space
    .slice(0, 100); // Limit to 100 characters
};

const validateInput = (input: string) => {
  if (!input) return false; // Reject empty input

  if (input.length > 100) return false; // Reject overly long input

  // Reject if it contains only special characters or spaces
  if (/^[^a-zA-Z0-9]+$/g.test(input)) return false;

  return true;
};

export { sanitizeInput, validateInput };
